export const NoInternet = () => (
  <>
    <div 
      key="nointernet" 
      className='vh-250 mt-4'
    >
      <p className='text-center fs-smaller text-danger'>
        Customer, abeg restart your network then try again 😟
      </p>
    </div>
  </>
)